import { MapLayer, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import { getContrast } from 'utils';

import 'leaflet.markercluster';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

class MarkerClusterGroup extends MapLayer {
  createLeafletElement(props) {
    const el = new L.markerClusterGroup({
      ...props,
      iconCreateFunction: this.createClusterIcon,
    });

    this.contextValue = {
      ...props.leaflet,
      layerContainer: el,
    };

    return el;
  }

  createClusterIcon(cluster) {
    const markers = cluster.getAllChildMarkers();
    const firstMarker = markers[0];

    const color = firstMarker.options.color || '#808080';
    const textColor = getContrast(color);

    return L.divIcon({
      html: `<div style="box-shadow: rgba(0,0,150,1) 1px 3px 20px; background-color: ${color}"><span style="color: ${textColor}; font-size: 14px; font-weight: 700">${cluster.getChildCount()}</span></div>`,
      className:
        'custom-marker-cluster leaflet-marker-icon marker-cluster leaflet-zoom-animated leaflet-interactive',
      iconSize: L.point(40, 40),
    });
  }
}

export default withLeaflet(MarkerClusterGroup);
