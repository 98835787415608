import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

import AppLogo from './appLogo';

import './style.css';

const Layout = ({ children }) => {
  return (
    <Modal
      wrapClassName='view-routing-details-header'
      open
      title={
        <div className='view-routing-details-header'>
          <AppLogo />
        </div>
      }
      footer={null}
      className='p_full-screen-modal'
      closable={false}
    >
      {children}
    </Modal>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export default Layout;
