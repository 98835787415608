import React from 'react';
import { Image } from 'antd';
import PropTypes from 'prop-types';

const styles = {
  expanded: {
    marginTop: '20px',
    marginLeft: '25px',
    cursor: 'pointer',
  },
};

const AppLogo = () => {
  const onclick = () => window.open('https://polibrassoftware.com.br/');

  const src = `${process.env.PUBLIC_URL}/assets/logo-main.png`;

  return (
    <Image
      onClick={onclick}
      width={148}
      height={51}
      style={styles.expanded}
      src={src}
      preview={false}
    />
  );
};

AppLogo.defaultProps = {
  company: { id: null, setup: {} },
  mobile: true,
  collapsed: false,
};

AppLogo.propTypes = {
  mobile: PropTypes.bool,
  collapsed: PropTypes.bool,
};

export default AppLogo;
