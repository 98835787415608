import React from 'react';

export const renderComponent = (Component, props) => (Component ? <Component {...props} /> : null);

export function getScrollingParent(el) {
  if (!(el instanceof HTMLElement)) {
    return null;
  }

  if (isScrollable(el)) {
    return el;
  }

  return getScrollingParent(el.parentNode);
}

function isScrollable(el) {
  const computedStyle = window.getComputedStyle(el);
  const overflowRegex = /(auto|scroll)/;
  const properties = ['overflow', 'overflowX', 'overflowY'];

  return properties.find((property) => overflowRegex.test(computedStyle[property]));
}
