import './style.css';

const GridValue = ({ layout = 'vertical', label = '', children, style }) => {
    let className = ['grid-value'];

    switch (layout) {
        case 'vertical':
            className.push('grid-value-vertical');
            break;
        case 'horizontal':
            className.push('grid-value-horizontal');
            break;
        default:
            break;
    }

    return (
        <div style={style} className={className.join(' ')}>
            {Boolean(label) && (<label> {label} </label>)}
            <span>
                {children}
            </span>
        </div>
    );
}

export default GridValue;