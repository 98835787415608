import React, { useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import useFirstRender from "hooks/useFirstRender";
import useResizeObserver from "hooks/useResizeObserver";
import { getScrollingParent } from "utils/components";

import "./style.css";

const Collapse = ({ visible, children }) => {
  const firstRender = useFirstRender();

  const parent = useRef(null);
  const child = useRef(null);

  useResizeObserver({
    ref: child,
    onResize: ({ width, height }) => {
      if (parent.current) {
        if (width !== parent.current.style.width && !firstRender && visible) {
          parent.current.style.width = `${width}px`;
        }
        if (height !== child.current.style.height && !firstRender && visible) {
          parent.current.style.height = `${height}px`;
        }
      }
    }
  });


  useEffect(() => {
    const scrolContainer = getScrollingParent(parent.current);

    if (scrolContainer) {
      let timer;

      const refresh = (count) =>
        setTimeout(() => {
          scrolContainer.dispatchEvent(new CustomEvent("scroll"));

          if (count < 10) {
            timer = refresh(count + 1);
          }
        }, 10);

      timer = refresh(0);

      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <Transition
      in={visible}
      appear
      timeout={300}
      onEntering={(node) => {
        node.style.height = `${child.current.offsetHeight}px`;
        node.style.width = `${child.current.offsetWidth}px`;
      }}
      onExiting={(node) => {
        node.style.height = `0px`;
        node.style.width = `0px`;
      }}
    >
      <div className="custom-collapse" ref={parent}>
        <div
          ref={child}
          style={{
            width: `fit-content`,
            height: `fit-content`
          }}
        >
          {children}
        </div>
      </div>
    </Transition>
  );
};

export default Collapse;
