import React from 'react';
import { BsPersonBadge } from 'react-icons/bs';
import { FaRoad } from 'react-icons/fa';
import { NumberOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { HistoryIcon, MapIcon } from './customIcons';

const Icon = ({ name, ...rest }) => {
  const components = {
    history: HistoryIcon,
    map: MapIcon,
    personbadge: BsPersonBadge,
    path: FaRoad,
  };
  const Component = components[name] || NumberOutlined;

  return <Component {...rest} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
