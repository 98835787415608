import React from 'react';
import { Result } from 'antd';

const Unauthorized = () => {
  return (
    <div className='wrapper-page fade-in'>
      <Result
        status='403'
        title='403'
        subTitle='Você não tem permissão para utilizar este recurso!'
      />
    </div>
  );
};

export default Unauthorized;
