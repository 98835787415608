import { useMemo } from 'react';
import { Collapse } from 'antd';
import moment from 'moment';
import { getContrast, getRouteOverview } from 'utils/index';

import { CustomEmpty } from 'components/elements';

import './style.css';

const { Panel } = Collapse;

const locale = {
  total_distance_day: 'Distância total',
  intra_route_day: 'Distância intra rotas',
  residencia_vendedor: 'Distância da casa do agente',
  average_sale: 'Venda média',
  potential_revenue: 'Média de faturamento potencial',
  qtd_lojas: 'Qde. de lojas',
};

export const RouteOverview = ({
  routeSeller = {},
  sellers_view = [],
  sellersResume = [],
  companyCode = '',
}) => {
  const routeInfo = useMemo(() => {
    const RouteOverviewBySeller = {};
    Object.entries(routeSeller).forEach(([key, values]) => {
      const { checkedKeys, directionsMap: directions } = values;
      const resumeSeller = getRouteOverview({
        sellers_view,
        checkedKeys,
        seller_code: key,
        directions,
        sellersResume,
        companyCode,
      });

      RouteOverviewBySeller[key] = resumeSeller;
    });

    return RouteOverviewBySeller;
  }, [companyCode, routeSeller, sellersResume, sellers_view]);

  return (
    <div className='itinerary-list-container' style={{ maxHeight: '600px' }}>
      <h1 className='header-menu'>Resumo da Rota</h1>
      <Collapse>
        {Object.entries(routeInfo).map(([key, value]) => {
          const { seller_code = '', seller_name = '' } = routeSeller[key];

          return (
            <Panel header={`${seller_code} - ${seller_name}`} key={key}>
              {!routeSeller[key].checkedKeys?.length ? (
                <CustomEmpty
                  description='Selecione o dia que deseja visualizar'
                  context={'table'}
                />
              ) : (
                <div className='itinerary-list itinerary-roteirizze-container'>
                  {Object.entries(value).map(([key, metric]) => {
                    const [weekly_frequency, day_number] = key.split('_');
                    const day = moment().day(day_number).format('dddd');
                    const contrastColor = getContrast(metric.color);
                    return (
                      <>
                        <h2>
                          Semana ({weekly_frequency}) - {day}
                        </h2>
                        {Object.entries(locale).map(([key, label], index) => (
                          <div
                            key={index}
                            className='itinerary-client-card'
                            style={{
                              backgroundColor: metric.color,
                            }}
                          >
                            <span
                              className='itinerary-roteirizze-container-service'
                              style={{ color: contrastColor, maxWidth: 'fit-content' }}
                            >
                              {' '}
                              {label}: {metric[key] ?? '-'}{' '}
                            </span>
                          </div>
                        ))}
                      </>
                    );
                  })}
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default RouteOverview;
