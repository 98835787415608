import React from 'react';
import { Result } from 'antd';

const NotFound = () => {
  return (
    <div className='wrapper-page fade-in'>
      <Result status='404' title='404' subTitle='Página não encontrada' />
    </div>
  );
};

export default NotFound;
