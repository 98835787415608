import moment from 'moment';
import Routes from 'routes';

moment.locale('pt-br');

const App = () => {
  return <Routes />;
};

export default App;
