import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';

const RouteCheck = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

RouteCheck.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  layout: PropTypes.string,
};

RouteCheck.defaultProps = {
  isPrivate: true,
};

export default RouteCheck;
