import React, { useEffect, useState } from 'react';
import { FiMinimize2 } from 'react-icons/fi';
import { Tooltip } from 'antd';
import { renderComponent } from 'utils/components';

import Collapse from '../Collapse';

import './style.css';

const VerticalCollapseMenu = (props) => {
  const {
    options = [],
    onChange,
    optionsProps = {},
    style = {},
    axis = {},
    open: startOpen,
    visible = true,
    selected,
    setSelected = () => {},
  } = props;
  const { x, y } = axis;

  const [open, setOpen] = useState(startOpen);

  useEffect(() => {
    if (selected && onChange) {
      onChange(selected);
    }
  }, [onChange, selected]);

  useEffect(() => {
    setSelected((selected) => {
      if (selected === null && startOpen) {
        return options[0];
      }
      return selected;
    });
    setOpen((prev) => (prev === false ? prev : startOpen));
  }, [options, setSelected, startOpen]);

  const handleSelect = (option) => {
    setSelected(option);
    if (option?.key === selected?.key) {
      if (open) {
        setSelected(null);
      }
      setOpen(!open);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <div
      className='vertical-collapse-menu'
      style={{
        right: x,
        top: y,
        ...style,
      }}
    >
      <Collapse visible={open}>
        <div key={`${selected}-${selected?.key}`} className='vertical-collapse-menu__content'>
          {selected && renderComponent(selected.component, optionsProps)}
        </div>
      </Collapse>
      <div
        className={`vertical-collapse-menu__options ${
          open ? 'vertical-collapse-menu__options--open' : ''
        }`}
      >
        {options.map((option, index) => (
          <Tooltip key={index} title={option.label} placement='left'>
            <div
              className={`vertical-collapse-menu__option ${
                selected && selected.key === option.key
                  ? 'vertical-collapse-menu__option--selected'
                  : ''
              }`}
              onClick={() => handleSelect(option)}
            >
              <span className='anticon'>{option.icon}</span>
            </div>
          </Tooltip>
        ))}
        {open && (
          <div className='vertical-collapse-menu__open' onClick={handleClose}>
            <FiMinimize2 size={24} />
          </div>
        )}
      </div>
    </div>
  );
};

export default VerticalCollapseMenu;
