import React, { useCallback, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Spin } from 'antd';
import { result } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';

const SearchControl = ({
  setMarkers,
  setSelectedOption,
  zoomInLocation,
  propsSelectedMarker = {},
  fullAddress,
  setLoading,
}) => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);

  const deboucedSearch = useDebounce(searchTerm, 200);

  const handleSearch = useCallback(
    async (value) => {
      try {
        if (!value) {
          return;
        }

        setLoadingSearch(true);
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${value}&addressdetails=1&accept-language=pt-br`,
        );
        const results = await response.json();

        setOptions(
          results.map((result) => {
            const label = result.display_name;
            const value = result.place_id;

            return { label, value, data: result };
          }),
        );

        if (fullAddress && results.length === 1) {
          const option = results[0];
          const label = option.display_name;
          const value = option.place_id;

          const marker = {
            position: { lat: option.lat, lng: option.lon },
            color: '#003667',
            draggable: true,
          };

          setSelectedOption({ label, value, data: result });
          setMarkers([marker]);
        }
        return Promise.resolve();
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      } finally {
        setLoadingSearch(false);
      }
    },
    [fullAddress, setSelectedOption, setMarkers],
  );

  const onSelectOption = (place_id) => {
    const option = options.find((option) => option.value === place_id);
    if (option) {
      const marker = {
        position: { lat: option.data.lat, lng: option.data.lon },
        color: '#003667',
        ...propsSelectedMarker,
      };

      setSelectedOption(option);
      setMarkers([marker]);
      setSearchTerm((prev) => option.label || prev);
      zoomInLocation();
    }
  };

  const findMarker = useCallback(
    async (fullAddress) => {
      if (fullAddress) {
        setLoading(true);
        handleSearch(fullAddress)
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [handleSearch, setLoading],
  );

  useEffect(() => {
    handleSearch(deboucedSearch).catch();
  }, [deboucedSearch, handleSearch]);

  useEffect(() => {
    findMarker(fullAddress);
  }, [fullAddress, findMarker]);

  return (
    <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 1000 }}>
      <AutoComplete
        onClick={(e) => e.preventDefault()}
        value={searchTerm}
        onSelect={onSelectOption}
        onSearch={(text) => {
          setSearchTerm(text);
        }}
        options={options}
        style={{ width: '400px' }}
        onPressEnter={handleSearch}
      >
        <Input
          size='large'
          className='custom-input-text'
          style={{ background: 'var(--neutral-10)' }}
          onClick={(e) => e.preventDefault()}
          placeholder='Buscar endereço'
          suffix={
            loadingSearch ? (
              <Spin indicator={<LoadingOutlined />} />
            ) : showClear ? (
              <i
                className='icon pi_times fade-in'
                onClick={() => {
                  setSearchTerm('');
                }}
                onMouseLeave={() => setShowClear(false)}
              />
            ) : (
              <i
                className='icon pi_search fade-in'
                onMouseEnter={() => {
                  if (searchTerm) {
                    setShowClear(true);
                  }
                }}
              />
            )
          }
        />
      </AutoComplete>
    </div>
  );
};

export default SearchControl;
