import { useMemo, useState } from 'react';

import { Icon, VerticalCollapseMenu } from 'components/elements';

import Itinerary from './Itinerary';
import LegendMapMenu from './LegendMapMenu';
import LegendSellersMenu from './LegendSellersMenu';
import RouteOverview from './RouteOverview';

import './style.css';

export const ConfigMenu = (props) => {
  const {
    menus = ['legend', 'itinerary', 'route-overview', 'sellers'],
    dataComponents = {},
    focusInLatLng,
  } = props;

  const [expandedKeys, setExpandedKeys] = useState([
    dataComponents.weeklyTree?.map((week) => week.key),
  ]);

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const params = {
    expandedKeys,
    setExpandedKeys,
    selectedKeys,
    setSelectedKeys,
    autoExpandParent,
    setAutoExpandParent,
    focusInLatLng,
  };

  const options = useMemo(
    () => [
      {
        label: 'Roteiro',
        key: 'legend',
        icon: <Icon name='map' size={24} />,
        component: LegendMapMenu,
      },
      {
        label: 'Itinerário',
        key: 'itinerary',
        icon: <Icon name='history' size={24} />,
        component: Itinerary,
      },
      {
        label: 'Resumo da Rota',
        key: 'route-overview',
        icon: <Icon name='path' size={24} />,
        component: RouteOverview,
      },
      {
        label: 'Clientes',
        key: 'sellers',
        icon: <Icon name='personbadge' size={24} />,
        component: LegendSellersMenu,
      },
    ],
    [],
  );

  const optionsMenu = useMemo(() => {
    const renderOptions = options.filter((option) => {
      return menus?.includes(option.key);
    });

    return renderOptions;
  }, [menus, options]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', zIndex: 1000 }}>
      <VerticalCollapseMenu
        selected={dataComponents['selected']}
        setSelected={dataComponents['setSelected']}
        options={optionsMenu}
        optionsProps={{ ...dataComponents, ...params }}
        style={{ top: 45 }}
        open
      />
    </div>
  );
};

export default ConfigMenu;
