import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import 'moment/locale/pt-br';

import App from './App';

import 'politokens/build/css/web/light.css';
import 'antd/dist/antd.min.css';
import 'leaflet/dist/leaflet.css';
import './index.css';
moment.locale('pt-br');
ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
