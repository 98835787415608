import { useMemo } from 'react';
import { Collapse } from 'antd';
import moment from 'moment';
import { getItinerary } from 'utils';
import { getContrast } from 'utils';

import CustomEmpty from 'components/elements/CustomEmpty';

const { Panel } = Collapse;
export const Itinerary = ({ routeSeller = {}, sellers_view = [], focusInLatLng = () => {} }) => {
  const itinerary = useMemo(() => {
    const hashTable = {};

    Object.entries(routeSeller).forEach(([key, values]) => {
      const { checkedKeys, directionsMap: directions } = values;
      const routeInfo = getItinerary(sellers_view, checkedKeys, key, directions);

      hashTable[key] = routeInfo;
    });

    return hashTable;
  }, [routeSeller, sellers_view]);

  return (
    <div className='itinerary-list-container' style={{ maxHeight: '600px' }}>
      <h1 className='header-menu'>Itinerário</h1>
      <Collapse>
        {Object.entries(itinerary).map(([key, value]) => {
          const { seller_name = '', seller_code = '' } = routeSeller[key];
          return (
            <Panel header={`${seller_code} - ${seller_name}`} key={key}>
              {!routeSeller[key]['checkedKeys']?.length ? (
                <CustomEmpty
                  description='Selecione o dia que deseja visualizar'
                  context={'table'}
                />
              ) : (
                <div className='itinerary-list itinerary-roteirizze-container'>
                  {Object.entries(value).map(([key, customers]) => {
                    const [weekly_frequency, day_number] = key.split('_');
                    const day = moment().day(day_number).format('dddd');
                    return (
                      <>
                        <h2>
                          Semana ({weekly_frequency}) - {day}
                        </h2>
                        {customers.map((item, index) => {
                          const contrastColor = getContrast(item.color);
                          return (
                            <div
                              key={item.index}
                              className='itinerary-client-card'
                              style={{ backgroundColor: item.color }}
                              onClick={() => {
                                const { latitude: lat, longitude: lng } = item;

                                focusInLatLng({ lat, lng });
                              }}
                            >
                              <div className='itinerary-roteirizze-container-service'>
                                <div
                                  className='itinerary-client-card-circle'
                                  style={{ height: 'fit-content', color: contrastColor }}
                                >
                                  {' '}
                                  {index + 1}º{' '}
                                </div>
                                <span style={{ color: contrastColor }}>{item.customer}</span>
                              </div>
                              <span style={{ color: contrastColor }}>{item.service}</span>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Itinerary;
