export const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, '');

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

export const getContrast = (hex) => {
  if (!hex) return undefined;

  const rgb = hexToRgb(hex);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;

  return brightness > 125 ? 'black' : 'white';
};

export const getItinerary = (
  sellers_view = [],
  checkedKeys = [],
  seller_code = '',
  directions = [],
) => {
  const getColor = (key) => directions.find((direction) => direction.key === key)?.color;

  const filteredCustomersSeller = sellers_view.filter(
    (seller) => seller.seller_code === seller_code,
  );
  const hashTable = {};

  filteredCustomersSeller.forEach((customer) => {
    const key = `${customer.weekly_frequency}_${customer.week_day}_${seller_code}`;
    if (checkedKeys.includes(key)) {
      const color = getColor(key);
      const formatedCustomer = {
        ...customer,
        color: !color ? 'var(--primary-600)' : color.includes('#') ? color : '#' + color,
      };
      if (hashTable[key]) {
        hashTable[key] = [...hashTable[key], formatedCustomer];
      } else {
        hashTable[key] = [formatedCustomer];
      }
    }
  });

  return hashTable;
};

export const getRouteOverview = ({
  sellers_view = [],
  checkedKeys = [],
  seller_code = '',
  directions = [],
  sellersResume = {},
  companyCode = '',
}) => {
  const compareSellerCods = (valueRef = '') => {
    return seller_code.replace(`${companyCode}.`, '') === valueRef.replace(`${companyCode}.`, '');
  };

  const calculateMetricsCustomers = (customers = [], { day_number, weekly_frequency }) => {
    const size = customers.length;
    const color = customers[0]?.color;
    const avgVendaMedia =
      customers.reduce((sum, customer) => sum + customer.average_sale, 0) / size;
    const avgFatPotencial =
      customers.reduce((sum, customer) => sum + customer.potential_revenue, 0) / size;

    const resumo =
      sellersResume.find((sv) => {
        return (
          compareSellerCods(sv.seller_code) &&
          sv.roteiro === weekly_frequency &&
          sv.day_number === (typeof day_number === 'string' ? parseInt(day_number) : day_number)
        );
      })?.resumo || {};

    const {
      intra_route_day = '-',
      percentual_residencia_vendedor = '-',
      residencia_vendedor = '-',
      total_distance_day = '-',
    } = resumo;

    return {
      intra_route_day,
      percentual_residencia_vendedor,
      residencia_vendedor,
      total_distance_day,
      qtd_lojas: size,
      average_sale: toBRL(avgVendaMedia),
      potential_revenue: toBRL(avgFatPotencial),
      color,
    };
  };

  const hashTable = getItinerary(sellers_view, checkedKeys, seller_code, directions);
  const response = {};

  Object.entries(hashTable).forEach(([key, customers]) => {
    const [weekly_frequency, day_number] = key.split('_');
    const metrics = calculateMetricsCustomers(customers, { weekly_frequency, day_number });
    response[key] = metrics;
  });

  return response;
};

export const toBRL = (value) => {
  if (!value || isNaN(value)) {
    return 'R$ 0,00';
  }

  value = parseFloat(value);

  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const inDiffFormatSellerAndSellerCode = (refSring = '', companyCode = '') => {
  if (!companyCode) {
    return false;
  }

  const includesCompanyCode = (refSring || '').includes(`${companyCode || ''}.`);
  if (includesCompanyCode) {
    return true;
  }

  return false;
};

const removeCompanyCode = (refSring = '', companyCode = '') => {
  if (!companyCode) {
    return refSring;
  }

  if (inDiffFormatSellerAndSellerCode(refSring, companyCode)) {
    return refSring.split('.')[1] ?? refSring;
  }

  return refSring;
};

export const formatSellerAndSellerCode = (data = [], companyCode = '', sellers = []) => {
  const response = [...data].map((sv) => {
    if (!sv.seller) {
      sv['seller'] = sv.seller_code;
    }

    return {
      ...sv,
      seller_code: removeCompanyCode(sv.seller_code, companyCode) || sv.seller_code,
      seller:
        sellers.find(
          (seller) => seller.seller_code === removeCompanyCode(sv.seller_code, companyCode),
        )?.name || sv.seller,
    };
  });

  return response;
};

export const formatValueToLocale = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
