import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/pt_BR';
import NotFound from 'pages/NotFound';
import Unauthorized from 'pages/Unauthorized';
import Unavailable from 'pages/Unavailable';
import ViewerMap from 'pages/ViewerMap';

import Route from './route-check';

const Routes = () => (
  <ConfigProvider locale={locale}>
    <Router>
      <Switch>
        <Route exact path={'/unauthorized'} component={Unauthorized} />
        <Route exact path={'/unavailable'} component={Unavailable} />
        <Route exact path={'/'} component={ViewerMap} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  </ConfigProvider>
);

export default Routes;
