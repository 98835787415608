import { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import api from 'service/api';
import { formatSellerAndSellerCode } from 'utils';

import MapRouting from 'components/containers/MapRouting';

import './style.css';

const ViewerMap = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [permissionToShow, setPermissionToShow] = useState(false);
  const [detailRouting, setDetailRouting] = useState({});

  const hashParams = useMemo(() => {
    const hash = window.location.search;
    const queryString = hash.split('?')[1];
    return new URLSearchParams(queryString);
  }, []);

  const params = useMemo(() => {
    const decodeAndReplace = (value) => {
      if (!value) return '';
      return decodeURIComponent(value).replace(/ /g, '+');
    };

    return {
      q: decodeAndReplace(hashParams.get('q')),
      p: decodeAndReplace(hashParams.get('p')),
    };
  }, [hashParams]);

  const fetchRouting = useCallback(() => {
    const url = '/smart-route-map-service';

    setLoading(true);
    api
      .get(url, {
        headers: {
          'Hash-Q': params.q,
          'Hash-P': params.p,
        },
      })
      .then((response) => {
        const { route: views = {}, sellers = [], company: companyCode, waypoints } = response.data;

        const item = {
          companyCode,
          sellers_view: formatSellerAndSellerCode(views.sellers_view || [], companyCode, sellers),
          sellers_view_resume: formatSellerAndSellerCode(
            views.sellers_view_resume || [],
            companyCode,
            sellers,
          ),
          routing_off_course: formatSellerAndSellerCode(
            views.routing_off_course || [],
            companyCode,
            sellers,
          ),
          sellers,
          waypoints,
        };

        item.sellers_view.map(
          (i) =>
            (i.service =
              !i.service || i.service === '0.0'
                ? '-'
                : i.service.length > 3
                ? i.service.slice(0, -3) + ' h'
                : i.service),
        );

        setDetailRouting(item);
        setPermissionToShow(true);
      })
      .catch(() => {
        history.push('/unavailable');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [history, params]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Tem certeza que deseja sair da página? Todas as ações serão desfeitas.';
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (params.p && params.q) {
      fetchRouting();
    }
  }, [fetchRouting, history, params.p, params.q]);

  useEffect(() => {
    if (!params.p || !params.q || (!loading && !permissionToShow)) {
      history.push('/unauthorized');
    }
  }, [history, loading, params.p, params.q, permissionToShow]);

  return (
    <>
      <>
        {loading ? (
          <Spin size='large' tip='Carregando roteiro...'>
            {' '}
            <div style={{ height: '91vh' }}></div>{' '}
          </Spin>
        ) : (
          <MapRouting
            heightMap='90vh'
            companyCode={detailRouting.companyCode}
            sellersResume={detailRouting.sellers_view_resume}
            data={detailRouting.sellers_view}
            sellers={detailRouting.sellers}
            routingOffCourse={detailRouting.routing_off_course}
            waypoints={detailRouting.waypoints || {}}
          />
        )}
      </>
    </>
  );
};

export default withRouter(ViewerMap);
