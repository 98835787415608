import React from 'react';
import { Result } from 'antd';

const Unavailable = () => {
  return (
    <div className='wrapper-page fade-in'>
      <Result
        status='500'
        title='500'
        subTitle='Serviço temporiariamente indisponível, tente novamente mais tarde. Se o problema persistir contate o suporte.'
      />
    </div>
  );
};

export default Unavailable;
