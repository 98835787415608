export const MapIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    {...props}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.833496 4.99984V18.3332L6.66683 14.9998L13.3335 18.3332L19.1668 14.9998V1.6665L13.3335 4.99984L6.66683 1.6665L0.833496 4.99984Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.6665 1.6665V14.9998'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.3335 5V18.3333'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const HistoryIcon = (props) => (
  <svg
    width='22'
    height='19'
    {...props}
    viewBox='0 0 22 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.2595 0.999584C7.16954 0.859584 2.99954 4.94958 2.99954 9.99958H1.20954C0.759542 9.99958 0.539542 10.5396 0.859542 10.8496L3.64954 13.6496C3.84954 13.8496 4.15954 13.8496 4.35954 13.6496L7.14954 10.8496C7.45954 10.5396 7.23954 9.99958 6.78954 9.99958H4.99954C4.99954 6.09958 8.17954 2.94958 12.0995 2.99958C15.8195 3.04958 18.9495 6.17958 18.9995 9.89958C19.0495 13.8096 15.8995 16.9996 11.9995 16.9996C10.3895 16.9996 8.89954 16.4496 7.71954 15.5196C7.31954 15.2096 6.75954 15.2396 6.39954 15.5996C5.97954 16.0196 6.00954 16.7296 6.47954 17.0896C7.99954 18.2896 9.90954 18.9996 11.9995 18.9996C17.0495 18.9996 21.1395 14.8296 20.9995 9.73958C20.8695 5.04958 16.9495 1.12958 12.2595 0.999584ZM11.7495 5.99958C11.3395 5.99958 10.9995 6.33958 10.9995 6.74958V10.4296C10.9995 10.7796 11.1895 11.1096 11.4895 11.2896L14.6095 13.1396C14.9695 13.3496 15.4295 13.2296 15.6395 12.8796C15.8495 12.5196 15.7295 12.0596 15.3795 11.8496L12.4995 10.1396V6.73958C12.4995 6.33958 12.1595 5.99958 11.7495 5.99958Z'
      fill='currentColor'
    />
  </svg>
);

export const MarkerIconWithText = (props) => {
  return (
    <svg width='15' height='20' viewBox='0 0 15 20' {...props} xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 0C3.36445 0 0 3.34699 0 7.46105C0 13.5907 6.75347 19.5758 7.04107 19.8277C7.17218 19.9425 7.33605 20 7.5 20C7.66395 20 7.82782 19.9426 7.95899 19.8277C8.24646 19.5759 15 13.5908 15 7.46105C15 3.34699 11.6356 0 7.5 0ZM7.5 5.36651C8.64877 5.36651 9.58335 6.30611 9.58335 7.46105C9.58335 8.616 8.64877 9.55559 7.5 9.55559C6.35123 9.55559 5.41665 8.616 5.41665 7.46105C5.41665 6.30611 6.35123 5.36651 7.5 5.36651Z' />
      <circle cx='7.5' cy='7.5' r='2.5' />
      <circle cx='7.5' cy='7.5' r='4.5' fill='white' fillOpacity='0.55' />
      <text
        x='50%'
        y='42%'
        dominantBaseline='middle'
        textAnchor='middle'
        fill={props.textColor}
        fontSize='6'
        fontWeight='bold'
        color={props.textColor}
        fontFamily='Mulish, sans-serif'
      >
        {props.label}
      </text>
    </svg>
  );
};

export const CloseIcon = () => (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.3232 8.35805L16.3091 2.38612C16.5712 2.12399 16.7185 1.76846 16.7185 1.39775C16.7185 1.02705 16.5712 0.671522 16.3091 0.409393C16.0469 0.147263 15.6914 0 15.3207 0C14.95 0 14.5945 0.147263 14.3324 0.409393L8.36042 6.39524L2.38849 0.409393C2.12636 0.147263 1.77084 -2.76198e-09 1.40013 0C1.02942 2.76199e-09 0.673898 0.147263 0.411768 0.409393C0.149639 0.671522 0.00237569 1.02705 0.00237569 1.39775C0.00237568 1.76846 0.149639 2.12399 0.411768 2.38612L6.39762 8.35805L0.411768 14.33C0.281293 14.4594 0.177732 14.6133 0.107059 14.783C0.0363863 14.9526 0 15.1346 0 15.3183C0 15.5021 0.0363863 15.6841 0.107059 15.8537C0.177732 16.0233 0.281293 16.1773 0.411768 16.3067C0.541178 16.4372 0.695141 16.5407 0.864777 16.6114C1.03441 16.6821 1.21636 16.7185 1.40013 16.7185C1.5839 16.7185 1.76585 16.6821 1.93548 16.6114C2.10512 16.5407 2.25908 16.4372 2.38849 16.3067L8.36042 10.3208L14.3324 16.3067C14.4618 16.4372 14.6157 16.5407 14.7854 16.6114C14.955 16.6821 15.1369 16.7185 15.3207 16.7185C15.5045 16.7185 15.6864 16.6821 15.8561 16.6114C16.0257 16.5407 16.1797 16.4372 16.3091 16.3067C16.4395 16.1773 16.5431 16.0233 16.6138 15.8537C16.6845 15.6841 16.7208 15.5021 16.7208 15.3183C16.7208 15.1346 16.6845 14.9526 16.6138 14.783C16.5431 14.6133 16.4395 14.4594 16.3091 14.33L10.3232 8.35805Z'
      fill='#2F80ED'
    />
  </svg>
);
