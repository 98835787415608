import { useMemo } from 'react';
import { Empty } from 'antd';

import './style.css';

const CustomEmpty = ({
  children,
  imageStyle = {},
  description = '',
  customViewBox = '0 0 232 225',
  className = 'empty-table',
  context,
  image,
}) => {
  const classes = useMemo(() => {
    const values = ['custom-empty'];

    if (typeof className === 'string') {
      values.push(className);
    } else if (Array.isArray(className)) {
      values.push(...className);
    }

    return values.join(' ');
  }, [className]);

  if (context === 'table') {
    customViewBox = '0 0 232 180';
  } else if (context === 'page') {
    customViewBox = '0 0 232 130';
    imageStyle = {
      height: 280,
      width: 280,
    };
  }
  return (
    <Empty
      className={classes}
      image={
        image || (
          <svg
            width='232'
            height='225'
            viewBox={customViewBox}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_1705_5966)'>
              <path
                d='M20.7972 86.0323C30.6543 42.6478 75.3588 5.4879 141.83 23.935C189.627 34.4625 242.629 89.9528 224.462 127.557C206.295 165.161 164.465 155.44 118.411 143.55C75.1774 136.771 12.2527 150.676 20.7972 86.0323Z'
                fill='#D1E9FF'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M72.7213 63.4473H55.0813L49.4814 67.6545H44.5813H35.3413V155.324C35.3413 160.527 39.441 164.771 44.5813 164.991V165H45.0013H157.636V67.6545H78.3213L72.7213 63.4473Z'
                fill='#E6E6E6'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M50.1489 69.6545H37.3413V155.324C37.3413 159.454 40.5954 162.819 44.6669 162.993L44.835 163H155.636V69.6545H77.6537L72.0538 65.4473H55.7489L50.1489 69.6545ZM55.0813 63.4473H72.7213L78.3213 67.6545H157.636V165H44.5813C39.441 164.78 35.3413 160.527 35.3413 155.324V67.6545H49.4814L55.0813 63.4473Z'
                fill='#1A1A1A'
              />
              <rect x='29' y='83' width='121' height='75' rx='4' fill='#0586FF' />
              <rect x='50' y='83' width='100' height='75' rx='4' fill='#006CD1' />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M43.4941 164V163.991C43.6318 163.997 43.7703 164 43.9094 164H43.4941ZM167.6 79.7529H53.4612V154.312C53.4612 159.662 49.1847 164 43.9094 164H144.772L151.467 163.985C151.605 163.991 157.909 164 158.048 164C163.324 164 167.6 159.662 167.6 154.312V79.7529Z'
                fill='#FCFCFC'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M52.4612 78.7529H168.6V154.312C168.6 160.201 163.889 165 158.048 165C157.938 165 152.067 164.992 151.469 164.985L144.775 165H45L44 163L44.5001 162.992C44.6233 162.997 44.3753 163 44.5 163C49.2097 163 52.4612 159.124 52.4612 154.312V78.7529ZM50.0571 163H144.772L151.488 162.985L151.51 162.986C151.506 162.986 151.523 162.986 151.566 162.986C151.61 162.987 151.683 162.987 151.788 162.987C151.887 162.988 152.006 162.988 152.143 162.989C152.262 162.989 152.394 162.99 152.537 162.99C153.149 162.992 153.954 162.993 154.759 162.995C156.37 162.998 157.98 163 158.048 163C162.758 163 166.6 159.124 166.6 154.312V80.7529H54.4612V154.312C54.4612 157.886 52.7265 161.058 50.0571 163Z'
                fill='#1A1A1A'
              />
            </g>
            <defs>
              <clipPath id='clip0_1705_5966'>
                <rect width='232' height='225' fill='white' />
              </clipPath>
            </defs>
          </svg>
        )
      }
      imageStyle={imageStyle}
      description={description}
    >
      {children}
    </Empty>
  );
};

export default CustomEmpty;
