import React, {
  createRef,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { Circle, Map, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Spin } from 'antd';
import L from 'leaflet';
import { find } from 'lodash';
import { groupBy } from 'lodash';
import { getContrast } from 'utils';

import 'leaflet-routing-machine';

import config from '../../../../package.json';
import { MarkerIconWithText } from '../Icon/customIcons';

import ConfigMenu from './ConfigMenu';
import MarkerClusterGroup from './MarkerClusterGroup';
import SearchControl from './SearchControl';

import './style.css';

export function encodeSvg(reactElement) {
  return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToString(reactElement));
}

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const RoteirizzeMap = forwardRef((props, ref) => {
  const {
    waypoints: assinateWaypoints = {},
    polylines = [],
    showConfirmButton,
    onSelectLocation,
    onConfirm,
    showSearch,
    propsSelectedMarker,
    onDragend,
    height = 600,
    directions = [],
    showItinerary = false,
    fullAddress,
    legendMapComponent,
    showClientClusters,
    showConfigMenu,
    sellersResume,
    weeklyTree,
    menus,
    dataComponents = {},
    hasSellerPin = true,
  } = props;
  const [showMap, setShowMap] = useState(false);
  const [markers, setMarkers] = useState(props.markers ? props.markers : []);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [lock, setLock] = useState(false);
  const [center, setCenter] = useState({ lat: -19.2400732, lng: -53.1805017 });
  const [zoom, setZoom] = useState(4);
  const [loading, setLoading] = useState(false);

  const mapRef = createRef();
  const polylinesRef = useRef([]);
  const markerRefs = useRef([]);
  const routeRefs = useRef([]);

  const groupedMarkers = useMemo(() => {
    const formatedMarkers = markers.map((marker) => ({ ...marker, group: marker.group || '0' }));
    return groupBy(formatedMarkers, 'group');
  }, [markers]);

  const className = useMemo(() => {
    const classes = ['roteirizze-map-container'];

    if (!showItinerary) {
      classes.push('remove-itinerary');
    }

    return classes.join(' ');
  }, [showItinerary]);

  const zoomInLocation = () => {
    setZoom(18);
  };

  const focusInLatLng = useCallback((lat, lng) => {
    setCenter(lat, lng);
    setZoom(18);
  }, []);

  const recalculateMarkers = (resetCenter = false) => {
    if (markers.length > 0) {
      const bounds = getBoundsBetweenMarkers();
      if (bounds) {
        setTimeout(() => {
          try {
            if (resetCenter) {
              mapRef.current?.leafletElement.fitBounds(bounds);
              const center = bounds.getCenter();
              if (center) {
                setCenter({ lat: center.lat, lng: center.lng });
                if (!fullAddress && markers.length === 1) {
                  zoomInLocation();
                }
              }
            }
          } catch {
            console.log('Cannot fit bounds');
          }
        }, 250);
      }
    }
  };

  const onMarkerClick = (id, action = false) => {
    if (lock) {
      return;
    }
    if (id === currentMarker?.id) {
      setCurrentMarker(null);
      return;
    }
    const findMarker = find(markers, { id });
    setCurrentMarker(action ? findMarker : null);
  };

  const handleLock = (lock = false) => {
    setLock(lock);
  };

  if (ref) {
    ref.current = {
      clickMarker: onMarkerClick,
      toggleLock: handleLock,
    };
  }

  const getBoundsBetweenMarkers = () => {
    const bounds = new L.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend(marker.position);
      polylines.forEach((line) => {
        bounds.extend(line.positions);
      });
    });
    return bounds;
  };

  const getIconAttributes = (marker = {}) => {
    const { color, letter } = marker.icon || {};
    const iconColor = color || marker.color;
    const label = letter || marker.label;

    return {
      iconUrl:
        marker.url ||
        encodeSvg(
          marker.icon && typeof marker.icon !== 'object' ? (
            marker.icon
          ) : (
            <MarkerIconWithText
              fill={iconColor}
              label={label}
              textColor={getContrast(iconColor || marker.color || '#000000')}
            />
          ),
        ),
      iconSize: marker.iconSize || new L.Point(45, 45),
    };
  };

  const handlerEventMarker = (markerID, event) => {
    const markerIndex = markers.findIndex((marker) => marker.id === markerID);
    if (markerIndex !== -1) {
      const updatedMarkers = [...markers];
      const newPosition = event.target.getLatLng();
      updatedMarkers[markerIndex].position = newPosition;
      setMarkers(updatedMarkers);
      zoomInLocation();
      onDragend?.(newPosition);
    }
  };

  const bindPopupToMarker = (marker, popupContent) => {
    try {
      marker.bindPopup(popupContent).openPopup();
      marker.bindPopup(popupContent).closePopup();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnConfirm = () => {
    const marker = markers[0];
    onConfirm?.(marker);
  };

  const handleSelectOption = useCallback(
    (option) => {
      onSelectLocation?.(option);
    },
    [onSelectLocation],
  );

  const renderMarkers = (markersToRender) => {
    return (markersToRender || markers).map((marker, index) => {
      if (marker.visible === false) {
        return;
      }

      return (
        <Fragment key={index}>
          {marker.radius && marker.position ? (
            <Circle
              radius={marker.radius}
              center={[marker.position?.lat, marker.position?.lng]}
              color={marker.color}
            />
          ) : null}
          <Marker
            color={marker.icon?.color}
            group={marker.group}
            onClick={() => {
              onMarkerClick(marker.id, true);
            }}
            ref={(el) => (markerRefs.current[index] = el)}
            draggable={marker.draggable}
            position={marker.position}
            icon={L.icon(getIconAttributes(marker))}
            onDragend={(event) => handlerEventMarker(marker.id, event)}
          />
        </Fragment>
      );
    });
  };

  useEffect(() => {
    if (
      (props.markers?.length > 0 && !markers.length) ||
      props.markers?.length !== markers.length
    ) {
      setMarkers(props.markers ? props.markers : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markers]);

  useEffect(() => {
    recalculateMarkers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  useEffect(() => {
    recalculateMarkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polylines]);

  useEffect(() => {
    setTimeout(() => setShowMap(true), 200);
  }, []);

  useEffect(() => {
    if (
      mapRef.current &&
      routeRefs.current.length !==
        directions.filter((direction) => direction.visible === true).length
    ) {
      const map = mapRef.current.leafletElement;
      routeRefs.current.forEach((route) => map.removeControl(route));
      routeRefs.current = [];
      if (directions.length > 0) {
        directions.forEach((direction) => {
          if (direction.visible) {
            const {
              origin = {},
              destination = {},
              color = '',
              infoContent = '',
              waypoints = [],
            } = direction || {};

            const hexColor = `#${color}`;
            let allWayPoints = [];
            const hashTableWayPoints = {};

            if (Object.keys(origin || {}).length) {
              const { source, ...location } = origin;
              allWayPoints.push({ source, location, isStartPoint: true });
            }

            allWayPoints = allWayPoints.concat(waypoints);

            if (Object.keys(destination || {}).length) {
              const { source, ...location } = destination;
              allWayPoints.push({ source, location, isEndPoint: true });
            }

            const assinateWaypoint = assinateWaypoints[direction.key];
            const lastDotIndex = assinateWaypoint.lastIndexOf('.');
            const assinatura = assinateWaypoint.substring(lastDotIndex + 1);

            const routing = L.Routing.control({
              waypoints: allWayPoints.map((waypoint, index) => {
                const indexString = index.toString();
                hashTableWayPoints[indexString] = waypoint;

                const { lat, lng } = waypoint.location;
                return L.latLng(lat, lng);
              }),
              createMarker: function (i, wp) {
                const indexString = i.toString();
                const waypoint = hashTableWayPoints[indexString];

                const isCustomer = !waypoint.isStartPoint && !waypoint.isEndPoint;
                const popupContent = isCustomer ? waypoint.infoContent : infoContent;
                const label = i === 0 && !waypoint.isStartPoint ? (i + 1).toString() : i.toString();

                const markerData = {
                  position: wp.latLng,
                  label: isCustomer ? label : hasSellerPin ? 'V' : '1',
                  color: isCustomer || !hasSellerPin ? hexColor : null,
                  popupContent,
                };

                const marker = L.marker(wp.latLng, {
                  icon: L.icon(getIconAttributes(markerData)),
                });

                bindPopupToMarker(marker, markerData.popupContent);

                return marker;
              },
              routeWhileDragging: false,
              lineOptions: {
                styles: [
                  {
                    color: hexColor,
                    opacity: 1,
                    weight: 4,
                  },
                ],
              },
              serviceUrl: `https://osm.polibr.com.br/caminhos/route/${assinatura}`,
              addWaypoints: false,
              draggableWaypoints: false,
              fitSelectedRoutes: true,
              show: false,
            }).addTo(map);

            routeRefs.current.push(routing);
          }
        });
      }
    }
  }, [mapRef, directions.length, directions, hasSellerPin, assinateWaypoints]);

  return (
    <div className={className}>
      <Spin spinning={loading} indicator={<LoadingOutlined />} tip='Carregando Mapa...'>
        <Card
          className='gx-card'
          style={{
            height: height,
            width: '100%',
          }}
        >
          {showMap && (
            <Map
              zoom={zoom}
              zoomControl={true}
              ref={mapRef}
              center={center}
              onClick={() => {
                onMarkerClick(null, false);
              }}
            >
              <div
                style={{ position: 'absolute', zIndex: 999, right: '0px', top: '16px' }}
                ref={(ref) => {
                  if (ref) {
                    L.DomEvent.disableClickPropagation(ref);
                    L.DomEvent.disableScrollPropagation(ref);
                  }
                }}
              >
                {showConfigMenu && (
                  <ConfigMenu
                    directions={directions}
                    legendMapComponent={legendMapComponent}
                    sellersResume={sellersResume}
                    weeklyTree={weeklyTree}
                    menus={menus}
                    dataComponents={{ ...dataComponents }}
                    focusInLatLng={focusInLatLng}
                  />
                )}
              </div>
              <TileLayer
                //url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                url='https://osm.polibr.com.br/maps5020/tile/{z}/{x}/{y}.png'
                attribution={`&copy; <a href="https://polibrassoftware.com.br/">Polibras Software | version: ${config.version}</a>`}
              />
              {showClientClusters === true ? (
                <>
                  {Object.entries(groupedMarkers).map(([key, groupMarker]) => (
                    <MarkerClusterGroup key={key}>{renderMarkers(groupMarker)}</MarkerClusterGroup>
                  ))}
                </>
              ) : (
                renderMarkers()
              )}
              {polylines?.map((polyline, index) => {
                if (!polyline.visible) {
                  return null;
                }
                return (
                  <Polyline
                    key={index}
                    positions={polyline.positions}
                    pathOptions={polyline.options}
                    ref={(ref) => (polylinesRef.current[index] = ref)}
                  />
                );
              })}
              {currentMarker && (
                <Popup
                  key={currentMarker.id}
                  offset={L.point(0, -15)}
                  position={currentMarker.position}
                >
                  {currentMarker.infoContent}
                </Popup>
              )}

              {showSearch && (
                <div
                  id='search-box'
                  key='search-box'
                  ref={(ref) => {
                    if (ref) {
                      L.DomEvent.disableClickPropagation(ref);
                      L.DomEvent.disableScrollPropagation(ref);
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <SearchControl
                    propsSelectedMarker={propsSelectedMarker}
                    setMarkers={setMarkers}
                    setSelectedOption={handleSelectOption}
                    zoomInLocation={zoomInLocation}
                    fullAddress={fullAddress}
                    setLoading={setLoading}
                  />
                </div>
              )}
            </Map>
          )}
        </Card>
      </Spin>
      {showConfirmButton && (
        <Button
          className='custom-button'
          type='primary'
          style={{ width: '100%' }}
          onClick={handleOnConfirm}
        >
          Confirmar local
        </Button>
      )}
    </div>
  );
});

export default RoteirizzeMap;
